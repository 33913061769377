import React from "react";
import PropTypes from "prop-types";
import { Checkbox, TableBody, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import { COLORS } from "../../styles/colors";
import PositionTableSummary from "./PositionTableSummary";
import { Link, useNavigate } from "react-router-dom";

const checkboxStyle = {
  borderRight: `1px solid ${COLORS.utility.border}`,
  width: 50,
  padding: 0,
  textAlign: "center",
};

const PositionTableBody = ({
  rows = [],
  selectedRows,
  onSelectRow,
  summary = {},
  columns = [],
}) => {
  const navigate = useNavigate();

  const onNavigateStage = (row) =>
    navigate(`/stages-system/edit/${row.stage_name}/${row.id}`);

  const formatNumber = (num) => {
    if (num === null || num === undefined || num === "") {
      return "-";
    } else if (num < 10 && num > -10) {
      return num.toFixed(2);
    } else {
      return Math.round(num);
    }
  }

  const colorDictionary = {
    "#F1FDC1": [
      "Clyde-D-0.52-1h/Long", "Clyde-C-0.7-1h/Long", "Rob-FA-0.67-1h/Long",
      "Rob-C-0.53-1h/Long", "Rob-E-0.4-1h/Long", "Clyde-K-0.65-1h/Short",
      "Rob-A-0.65-1h/Short", "Rob-G-0.63-1h/Short"
    ],
    "#CECDFD": [
      "Rob-H-0.67-1h/Long", "Rob-K-0.5-1h/Long", "Rob-R-0.4-1h/Long",
      "John-T-0.63-1h/Long", "John-K-0.53-1h/Long", "John-K-0.41-1h/Long",
      "John-B-0.63-1h/Short", "Ryan-AY-0.52-1h/Short", "Ryan-A-0.63-1h/Short",
      "Ryan-A-0.52-1h/Short"
    ],
    "#C8FCFF": [
      "Mickey-E-0.63-1h/Long", "Mickey-E-0.5-1h/Long", "Mickey-E-0.39-1h/Long",
      "Ryan-C-0.52-1h/Long", "Ryan-D-0.41-1h/Long", "Luka-T-0.41-1h/Long",
      "Luka-T-0.51-1h/Long", "Luka-C-0.5-1h/Short", "Luka-D-0.4-1h/Short",
      "ALARM-A-0.5-1h/Short", "ALARM-B-0.4-1h/Short"
    ]
  };

  function getColorByName(name) {
    for (const [color, names] of Object.entries(colorDictionary)) {
      if (names.includes(name)) {
        return color;
      }
    }
    return "#FFFFFF";
  }

  const getColorByPriceComparison = (direction, avgEnterPrice, currentPrice) => {
    if (direction === 'long' && avgEnterPrice > currentPrice) {
      return '#FFF3C7';
    } else if (direction === 'short' && avgEnterPrice < currentPrice) {
      return '#FFF3C7';
    } else {
      return '#FFFFFF';
    }
  };

  const getColorByCoeff = (coeff) => {
    if (coeff >= 0.39 && coeff < 0.5) {
      return '#C8EBFF';
    } else if (coeff >= 0.5 && coeff < 0.6) {
      return '#FFDDB5';
    } else if (coeff >= 0.6 && coeff <= 0.7) {
      return '#CEFAD0';
    } else {
      return '#FFFFFF';
    }
  }

  const getColorByDirection = (dir) => {
    if (dir === 'long') {
      return '#CEFAD0';
    } else if (dir === 'short') {
      return '#FFDADA';
    } else {
      return '#FFFFFF';
    }
  }

  const getColorByPnl = (res) => {
    if (res < 0) {
      return '#FFF3C7';
    } else {
      return '#FFFFFF';
    }
  }

  return (
    <TableBody>
      {rows.map((row) => (
        <TableRow key={row.id}>
          <TableCell padding="checkbox" sx={checkboxStyle}>
            <Checkbox
              checked={!!selectedRows.find((item) => item.id === row.id)}
              onClick={() => onSelectRow(row)}
            />
          </TableCell>
          {Object.entries(row).map(([key, value]) => {
            switch (key) {
              case "id":
                return null;

              case "name":
                return (
                  <TableCell key={key}>
                    <Link
                      sx={{ cursor: "pointer" }}
                      onClick={() => onNavigateStage(row)}
                    >
                      {value}
                    </Link>
                  </TableCell>
                );

              case "created_at":
                return (
                  <TableCell key={key}>
                    {format(value, "dd.MM.yy HH:mm:ss") ?? "-"}
                  </TableCell>
                );

              case "strategy":
                return <TableCell key={key} sx={{ bgcolor: getColorByName(value) }}>{value ?? "-"}</TableCell>;

              case "avg_enter_price":
                return <TableCell key={key} sx={{ bgcolor: getColorByPriceComparison(row.direction, row.avg_enter_price, row.current_price) }}>{value ?? "-"}</TableCell>;

              case "direction":
                return <TableCell key={key} sx={{ bgcolor: getColorByDirection(value) }}>{value ?? "-"}</TableCell>;

              case "k_exit_coefficient":
                return <TableCell key={key} sx={{ bgcolor: getColorByCoeff(value) }}>{value ?? "-"}</TableCell>;

              case "position_size_usd":
                return (
                  <TableCell key={key}>{value?.toFixed(0) ?? "0"}</TableCell>
                );

              case "r_pnl_usd":
              case "u_pnl_usd":
                return <TableCell key={key} sx={{ bgcolor: getColorByPnl(value) }}>{formatNumber(value)}</TableCell>;

              case "inc_vola":
              case "atr_i_minus_1_percent":
              case "atr_t_percent":
                return (
                  <TableCell key={key}>{value?.toFixed(2) ?? "0"}</TableCell>
                );

              case "skip_check_by_timer":
              case "skip_check_by_distance":
                return <TableCell key={key}>{value ? "+" : "–"}</TableCell>;

              default:
                return <TableCell key={key}>{value ?? "-"}</TableCell>;
            }
          })}
        </TableRow>
      ))}

      <PositionTableSummary summary={summary} columns={columns} />
    </TableBody>
  );
};

PositionTableBody.propTypes = {
  rows: PropTypes.array,
  onSelectRow: PropTypes.func,
  selectedRows: PropTypes.array,
  summary: PropTypes.object,
  columns: PropTypes.array,
};

export default PositionTableBody;
